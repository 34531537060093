<template>
  <div>
    <v-row dense>
      <v-col
        md="3"
        cols="12"
      >
        <deposit-slip-info
          v-on:data="on_selected_collector"
          :collector="collector"
        ></deposit-slip-info>
      </v-col>
      <v-col
        md="9"
        cols="12"
      >
        <v-card
          flat
          dense
          v-if="this.collector_id!=0"
        >
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h5 class="font-weight-light">PAYMENTS SECTION</h5>
            </v-toolbar-title>
            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
            <strong>
              <h3 class="font-weight-light"> Overall Balance: {{balance}}</h3>
            </strong>
          </v-toolbar>
          <v-card-text>
            <v-col
              md="12"
              cols="12"
            >
            </v-col>
            <v-row dense>
              <v-col
                md="5"
                cols="12"
              >
                <v-select
                  class="mx-2"
                  :items="payment_for_data"
                  label="Payment For"
                  required
                  :rules="rules.combobox_rule"
                  v-model="payment_for"
                  dense
                  @change="reset"
                ></v-select>
              </v-col>
              <v-col
                md="7"
                cols="12"
                v-if="payment_for!=''"
              >
                <v-combobox
                  class="mx-2"
                  :append-icon="icons.mdiAccountSearchOutline"
                  v-model="search"
                  @keyup="searching($event.target.value)"
                  @change="get_search_items_info"
                  :items="search_items"
                  item-value="id"
                  item-text="name"
                  placeholder="Search by (Lastname/Firstname/CMF #/Account #)"
                  ref="memberCombobox"
                  dense
                ></v-combobox>
              </v-col>
              <v-col
                md="4"
                cols="12"
                v-if="payment_for!=''"
              >
                <v-text-field
                  v-model="id_no"
                  label="ID #"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                md="8"
                cols="12"
                v-if="payment_for!=''"
              >
                <v-text-field
                  v-model="name"
                  label="Name"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                md="12"
                cols="12"
                v-if="payment_for!=''"
              >
                <payment-sections
                  :key="key"
                  v-on:data="on_save"
                  v-on:collector="on_save"
                  :collector_id="collector_id"
                  :payment_for="payment_for"
                  :input_amount="input_amount"

                  :selected_search_items="selected_search_items"

                  :deposit_slip_id="deposit_slip_id"
                  :balance="balance"
                  :or_series_available="or_series_available"
                  :or_series_available_id="or_series_available_id"
                ></payment-sections>
              </v-col>
              <v-col
                md="12"
                cols="12"
              >
                <h2>Payments Data</h2>
                <payments-data
                  v-on:collector="on_save"
                  :collector_id="collector_id"
                  :payments_data="payments_data"></payments-data>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import DepositSlipInfo from '@/views/payments/payment_for/PaymentsDepositSlipInfo'
  import PaymentSections from '@/views/payments/payment_for/PaymentSections'
  import PaymentsData from '@/views/payments/payment_for/PaymentsData'

  export default {
    props: {
      savings_date: String
    },
    components: {
      DepositSlipInfo,
      PaymentSections,
      PaymentsData,
    },
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline
        },
      }
    },
    data() {
      return {
        payment_for_data: [],

        search: '',
        search_member: '',
        search_items: [],
        selected_search_items: null,

        key: 0,
        collector: 0,
        collector_id: 0,
        deposit_slip_id: 0,
        balance: 0,
        or_series_available: 'NA',
        or_series_available_id: 0,

        id_no: '',
        name: '',
        payment_for: '',
        input_amount: false,

        payments_data: [],
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'user_id', 'position']),
    },
    methods: {
      ...mapActions('members_information', ['search_members_unpaid', 'search_members_paid', 'search_corporate_unpaid']),
      ...mapActions('loans_data', ['search_members_have_loan_unpaid']),
      ...mapActions('processing_payment', ['search_members_have_unpaid_loan_processing_fee']),
      ...mapActions('loans_insurance_payment', ['search_members_have_unpaid_loan_insurance_fee']),
      ...mapActions('gadget_loans_data', ['search_members_have_gadget_loan_unpaid']),
      ...mapActions('gadget_processing_payment', ['search_members_have_unpaid_gadget_loan_processing_fee']),
      ...mapActions('raw_lots_rent', ['search_members_have_raw_lots_unpaid']),
      ...mapActions('regular_savings_data', ['search_savings_not_yet_deposit_in_bank']),
      ...mapActions('share_capital', ['search_members_have_unpaid_share_capital']),
      ...mapActions('time_deposits', ['search_members_have_not_yet_deposit_time']),
      ...mapActions('mortuary_insurance_payment', ['search_members_have_unpaid_mortuary_insurance']),
      ...mapActions('foundation_payment', ['search_members_have_unpaid_foundation']),
      ...mapActions('transmital_passbooks', ['search_passbook_not_yet_deposit_in_bank']),
      ...mapActions('coop_protek_information', ['search_members_have_coop_protek_account_unpaid', 'search_members_have_coop_protek_account_paid']),
      reset() {
        this.search = ''
        this.search_items = []
        this.search_member = ''
        this.selected_search_items = null
        this.id_no = ''
        this.name = ''
      },
      on_selected_collector(value) {
        if (this.position === 'TELLER') {
          this.payment_for_data = ['Regular Savings', 'Share Capital', 'Time Deposit', 'Passbook Deposit']
        } else if (this.position === 'ACCOUNT') {
          this.payment_for_data = ['Coop Protek Account Fee', 'Coop Protek Savings']
        } else {
          if (value.collector_name.includes('FOUNDATION')) {
            this.payment_for_data = ['Foundation']
          } else if (value.collector_name.includes('LOAN INSURANCE')) {
            this.payment_for_data = ['Loan Insurance']
          } else if (value.collector_name.includes('LOAN PAYMENT')) {
            this.payment_for_data = ['Loan Payment', 'Gadget Payment', 'Raw Lots Payment']
          } else if (value.collector_name.includes('MEMBERSHIP FEE')) {
            this.payment_for_data = ['Membership Fee', 'Corporate Account Fee',]
          } else if (value.collector_name.includes('MORTUARY INSURANCE')) {
            this.payment_for_data = ['Mortuary Insurance']
          } else if (value.collector_name.includes('PROCESSING FEE')) {
            this.payment_for_data = ['Loan Processing Fee']
          } else {
            this.payment_for_data = ['Loan Payment']
            // this.payment_for_data = ['Membership Fee', 'Loan Payment', 'Loan Processing Fee', 'Loan Insurance', 'Gadget Payment', 'Gadget Processing Fee', 'Share Capital','Time Deposit'
            //   , 'Mortuary Insurance', 'Foundation', 'Raw Lots Payment']
          }
        }
        this.search = ''
        this.search_items = []
        this.search_member = ''
        this.selected_search_items = null
        this.id_no = ''
        this.name = ''

        this.collector_id = value.collector_id
        this.deposit_slip_id = value.deposit_slip_id
        this.balance = parseFloat(value.balance)
        this.or_series_available = value.or_series_available
        this.or_series_available_id = parseInt(value.or_series_available_id)

        this.payments_data = value.payments_data
        this.collector = -1
      },
      on_save(value) {
        this.collector = value
      },
      searching(value) {
        this.key++
        this.search_member = value
      },
      searching_search_items(value) {
        this.input_amount = false
        switch (this.payment_for) {
          case "Membership Fee":
            this.search_members_unpaid({
              branch_id: this.branch_id,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Corporate Account Fee":
            this.search_corporate_unpaid({
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Coop Protek Account Fee":
            this.search_members_have_coop_protek_account_unpaid({
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Coop Protek Savings":
            this.search_members_have_coop_protek_account_paid({
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Loan Payment":
            // if (this.user_id == 2) {
            //   this.input_amount = true
            // }
            this.search_members_have_loan_unpaid({
              branch_id: this.branch_id,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Gadget Payment":
            // if (this.user_id == 2) {
            //   this.input_amount = true
            // }
            this.search_members_have_gadget_loan_unpaid({
              branch_id: this.branch_id,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Loan Insurance":
            this.search_members_have_unpaid_loan_insurance_fee({
              branch_id: this.branch_id,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Loan Processing Fee":
            this.search_members_have_unpaid_loan_processing_fee({
              branch_id: this.branch_id,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Gadget Processing Fee":
            this.search_members_have_unpaid_gadget_loan_processing_fee({
              branch_id: this.branch_id,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Share Capital":
            this.search_members_have_unpaid_share_capital({
              branch_id: this.branch_id,
              date: this.savings_date,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Time Deposit":
            this.search_members_have_not_yet_deposit_time({
              branch_id: this.branch_id,
              date: this.savings_date,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Mortuary Insurance":
            this.search_members_have_unpaid_mortuary_insurance({
              branch_id: this.branch_id,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Foundation":
            this.search_members_have_unpaid_foundation({
              branch_id: this.branch_id,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Miscellaneous":
            this.input_amount = true
            this.search_members_paid({
              branch_id: this.branch_id,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Raw Lots Payment":
            this.search_members_have_raw_lots_unpaid({
              db_url: this.branch_id,
              search_word: value
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Regular Savings":
            this.search_savings_not_yet_deposit_in_bank({
              branch_id: this.branch_id,
              search_word: value,
              date: this.savings_date
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          case "Passbook Deposit":
            this.search_passbook_not_yet_deposit_in_bank({
              branch_id: this.branch_id,
              search_word: value,
              date: this.savings_date
            })
              .then(response => {
                this.search_items = response.data
              })
            break
          default:
            break
        }
      },
      get_search_items_info() {
        if (this.search_items.length > 0 && this.search != null) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.selected_search_items = this.search_items[index]
            this.id_no = this.selected_search_items.id_no
            this.name = this.selected_search_items.name
            if (this.selected_search_items.due_amounts != undefined) {
              this.input_amount = this.selected_search_items.due_amounts.length === 0
            }

          } else {
            this.name = ''
            this.id_no = ''
          }
        } else {
          this.name = ''
          this.id_no = ''
        }
      },
    }
  }
</script>
