<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-uppercase">
            ID
          </th>
          <th class="text-center text-uppercase">
            Name
          </th>
          <th class="text-center text-uppercase">
            OR Date
          </th>
          <th class="text-center text-uppercase">
            OR #
          </th>
          <th class="text-center text-uppercase">
            Net Collection
          </th>
          <th class="text-center text-uppercase">
            Payment For
          </th>
          <th class="text-center text-uppercase">
            Actions
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in payments_data"
          :key="item.id"
        >
          <td>{{ item.id_no }}</td>
          <td class="text-center">
            {{ item.name }}
          </td>
          <td class="text-center">
            {{ item.or_date }}
          </td>
          <td class="text-center">
            {{ item.or_no }}
          </td>
          <td class="text-center">
            {{ item.amount }}
          </td>
          <td class="text-center">
            {{ item.payment_for }}
          </td>
          <td class="text-center">
            <div
              v-if="payments_data.map(function(x) {return x.id; }).indexOf(item.id) == (payments_data.length-1)">
              <v-icon
                class="mr-2"
                color="error"
                @click="delete_payment(payments_data.map(function(x) {return x.id; }).indexOf(item.id))"
              >
                {{icons.mdiDelete}}
              </v-icon>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiDelete} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import snackBarDialog from "@/components/dialogs/notifications_dialog/Snackbar";

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiDelete,
        },
      }
    },
    props: {
      payments_data: Array,
      collector_id: Number,
    },
    computed: {
      ...mapGetters('authentication', ['branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_payment', ['delete_members_payment','delete_corporate_payment']),
      ...mapActions('loans_payment', ['delete_loans_payment']),
      ...mapActions('processing_payment', ['delete_loan_processing_payment']),
      ...mapActions('share_capital', ['delete_share_capital']),
      ...mapActions('time_deposits', ['delete_time_deposit']),
      ...mapActions('loans_insurance_payment', ['delete_loans_insurance_payment']),
      ...mapActions('mortuary_insurance_payment', ['delete_mortuary_insurance_payment']),
      ...mapActions('foundation_payment', ['delete_foundation_payment']),
      ...mapActions('gadget_loans_payment', ['delete_gadget_loans_payment']),
      ...mapActions('gadget_processing_payment', ['delete_gadget_loan_processing_payment']),
      ...mapActions('raw_lots_payment', ['delete_raw_lots_payment']),
      ...mapActions('regular_savings_data', ['delete_savings_deposit_in_bank']),
      ...mapActions('transmital_passbooks', ['delete_passbook_deposit_in_bank']),
      delete_payment(index) {
        var data = this.payments_data[index]
        var splited_id = data.id.split('-')

        switch (data.payment_for) {
          case "MEMBERSHIP FEE":
            this.delete_members_payment({
              members_payment_id: splited_id[0],
              member_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "CORPORATE ACCOUNT FEE":
            this.delete_corporate_payment({
              members_payment_id: splited_id[0],
              member_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "COOP PROTEK ACCOUNT FEE":
            this.delete_coop_protek_membership_payment({
              members_payment_id: splited_id[0],
              member_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "LOANS PAYMENT": {
            var due_date = data.due_date
            var indexOfAmount = data.amount / data.amount_due
            if (parseInt(indexOfAmount) > 0) {
              switch (data.mode_of_loan) {
                case "Weekly":
                  due_date = moment(data.due_date, 'MMMM D, YYYY').subtract((7 * indexOfAmount), "day").format('MMMM D, YYYY')
                  break;
                case "15/30":
                  due_date = moment(data.due_date, 'MMMM D, YYYY').subtract((15 * indexOfAmount), "day").format('MMMM D, YYYY')
                  break;
                case "Monthly":
                  due_date = moment(data.due_date, 'MMMM D, YYYY').subtract((1 * indexOfAmount), "months").format('MMMM D, YYYY')
                  break;
                default:
                  break;
              }
            }
            this.delete_loans_payment({
              loans_payment_id: splited_id[0],
              loans_data_id: data.loans_data_id,
              member_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          }
          case "GADGET LOANS PAYMENT": {
            var due_date = data.due_date
            var indexOfAmount = data.amount / data.amount_due
            if (parseInt(indexOfAmount) > 0) {
              switch (data.mode_of_loan) {
                case "Weekly":
                  due_date = moment(data.due_date, 'MMMM D, YYYY').subtract((7 * indexOfAmount), "day").format('MMMM D, YYYY')
                  break;
                case "15/30":
                  due_date = moment(data.due_date, 'MMMM D, YYYY').subtract((15 * indexOfAmount), "day").format('MMMM D, YYYY')
                  break;
                case "Monthly":
                  due_date = moment(data.due_date, 'MMMM D, YYYY').subtract((1 * indexOfAmount), "months").format('MMMM D, YYYY')
                  break;
                default:
                  break;
              }
            }
            this.delete_gadget_loans_payment({
              gadget_loans_payment_id: splited_id[0],
              loans_data_id: data.loans_data_id,
              member_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          }
          case "LOAN INSURANCE":
            this.delete_loans_insurance_payment({
              loans_insurance_payment_id: splited_id[0],
              loans_data_id: data.loans_data_id,
              member_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "LOANS PROCESSING FEE":
            this.delete_loan_processing_payment({
              processing_payment_id: splited_id[0],
              loans_data_id: data.loans_data_id,
              member_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "GADGET LOANS PROCESSING FEE":
            this.delete_gadget_loan_processing_payment({
              gadget_processing_payment_id: splited_id[0],
              loans_data_id: data.loans_data_id,
              member_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "SHARE CAPITAL":
            this.delete_share_capital({
              share_capital_id: splited_id[0],
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "TIME DEPOSIT":
            this.delete_time_deposit({
              time_deposit_id: splited_id[0],
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "MORTUARY INSURANCE":
            this.delete_mortuary_insurance_payment({
              mortuary_insurance_payment_id: splited_id[0],
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "FOUNDATION PAYMENT":
            this.delete_foundation_payment({
              foundation_payment_id: splited_id[0],
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "Raw Lots Payment":
            this.delete_raw_lots_payment({
              raw_lots_payment_id: splited_id[0],
              member_id: data.member_id,
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,

              id: data.series_or_id,
              branch_id: this.branch_id,
              collector_id: this.collector_id,
              or_series: data.or_series
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "REGULAR SAVINGS":
            this.delete_savings_deposit_in_bank({
              savings_id: splited_id[0],
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          case "PASSBOOK FEE":
            this.delete_passbook_deposit_in_bank({
              savings_id: splited_id[0],
              restored_amount: data.amount,
              deposit_slip_id: data.deposit_slip_id,
            })
              .then(() => {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: data.payment_for + ' DELETED SUCCESSFULLY !',
                })
                this.$emit('collector', this.collector_id)
                this.saving = false
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
            break
          default:
            break
        }
      }
    },
  }
</script>
